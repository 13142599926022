/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	/*
	* Global ScrollMagic controller
	*/
	var controller = new ScrollMagic.Controller();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript

        /*
        * Window scroll to top on load
        */
        $(document).ready(function() {
					$(document.body).animate({scrollTop: '0px'}, 1000);
				});

        /*
        * Header animation with GSAP
        */
        window.onload = function () {
        	var doc = document.getElementById('header-logo').getSVGDocument();
        	var eagle = doc.getElementById('eagle'),
        			letterP = doc.getElementById('p'),
        			letter2 = doc.getElementById('_x32_'),
		        	letterR = doc.getElementById('r'),
		        	letterN = doc.getElementById('n'),
		        	letterU = doc.getElementById('u'),
		        	letterB = doc.getElementById('b'),
		        	letterA = doc.getElementById('a'),
		        	letterY = doc.getElementById('y'),
		        	letterG = doc.getElementById('g'),
		        	letterO = doc.getElementById('o'),
		        	letterL = doc.getElementById('l'),
		        	letterF = doc.getElementById('f'),
		        	letterL2 = doc.getElementById('l_1_'),
		        	letterI = doc.getElementById('i'),
		        	letterN2 = doc.getElementById('n_1_'),
		        	letterK = doc.getElementById('k'),
		        	letterS = doc.getElementById('s');

		      var menu = $('header .menu li'),
		      		langMenu = $('header .lang'),
		      		golfBox = $('header .login .button-diamonds');

	        var logoAnim = new TimelineMax({paused: true});
			        logoAnim.set(eagle, {scale: 0, opacity: 0, transformOrigin: 'center center', ease: Circ.easeInOut}, 0);
			        logoAnim.to(eagle, 1, {scale: 1, opacity: 1, transformOrigin: 'center center', ease: Expo.easeInOut}, 0.025);
			        logoAnim.to(letterP, 0.25, {opacity: 1}, 0.05);
			        logoAnim.to(letter2, 0.25, {opacity: 1}, 0.075);
			        logoAnim.to(letterR, 0.25, {opacity: 1}, 0.1);
			        logoAnim.to(letterN, 0.25, {opacity: 1}, 0.125);
			        logoAnim.to(letterU, 0.25, {opacity: 1}, 0.15);
			        logoAnim.to(letterB, 0.25, {opacity: 1}, 0.175);
			        logoAnim.to(letterA, 0.25, {opacity: 1}, 0.2);
			        logoAnim.to(letterY, 0.25, {opacity: 1}, 0.225);
			        logoAnim.to(letterG, 0.25, {opacity: 1}, 0.25);
			        logoAnim.to(letterO, 0.25, {opacity: 1}, 0.275);
			        logoAnim.to(letterL, 0.25, {opacity: 1}, 0.3);
			        logoAnim.to(letterF, 0.25, {opacity: 1}, 0.325);
			        logoAnim.to(letterL2, 0.25, {opacity: 1}, 0.35);
			        logoAnim.to(letterI, 0.25, {opacity: 1}, 0.375);
			        logoAnim.to(letterN2, 0.25, {opacity: 1}, 0.4);
			        logoAnim.to(letterK, 0.25, {opacity: 1}, 0.425);
			        logoAnim.to(letterS, 0.25, {opacity: 1}, 0.45);
			        logoAnim.staggerTo(menu, 0.25, {opacity: 1}, 0.025, 0.3);
			        logoAnim.to(langMenu, 0.25, {opacity: 1}, 0.575);
			        logoAnim.to(golfBox, 0.25, {opacity: 1}, 0.6);

	        var headerBody = $('header'),
	        		headerLayer = $('header .color-overlay');

	        var headerAnim = new TimelineMax({
	        			paused: true,
	        			onComplete: function(){
	        				$('html,body').animate({scrollTop:0},0);

				          logoAnim.play();

				          var contentAnim = new TimelineMax({paused: true});
        							contentAnim.to($('.wrap.container'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0);

        					contentAnim.play();
				       	}
	        		});
	        		headerAnim.set(headerLayer, {css:{transform: 'translateY(0%)'}}, 0.1);
							headerAnim.to(headerBody, 0.3, {css:{transform: 'translateY(0%)'}, ease: Linear.easeInOut}, 0.2);
	        
	        headerAnim.play();
		    };

		    /*
				* Input add classes
				*/
				if (!String.prototype.trim) {
					(function() {
						var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
						String.prototype.trim = function() {
							return this.replace(rtrim, '');
						};
					})();
				}

				function onInputFocus(ev) {
					ev.target.parentNode.classList.add('input--filled');
				}

				function onInputBlur(ev) {
					if(ev.target.value.trim() === '') {
						ev.target.parentNode.classList.remove('input--filled');
					}
				}

				function onInputBlurWait(ev) {
					setTimeout(function(){
					  if(ev.target.value.trim() === '') {
							ev.target.parentNode.classList.remove('input--filled');
						}
					}, 150);
				}

				[].slice.call(document.querySelectorAll('input.input__field')).forEach(function(inputEl) {
					if (inputEl.value.trim() !== '') {
						inputEl.parentNode.classList.add('input--filled');
					}

					inputEl.addEventListener('focus', onInputFocus);

					if ($(inputEl).hasClass('wait-for-it')) {
						inputEl.addEventListener('blur', onInputBlurWait);
					} else {
						inputEl.addEventListener('blur', onInputBlur);
					}
				});

				[].slice.call( document.querySelectorAll( 'textarea.input__field' ) ).forEach(function(inputEl) {
					if (inputEl.value.trim() !== '' ) {
						inputEl.parentNode.classList.add('input--filled');
					}

					inputEl.addEventListener('focus', onInputFocus);
					inputEl.addEventListener('blur', onInputBlur);
				} );


				/*
				* Open GolfBox login
				*/
				var loginContainer = $('header .golfbox-login-container'),
						$loginButton = $('header .login'),
						$loginCloseButton = $('header .golfbox-login-container .close-button');

				var golfboxAnim = new TimelineMax({paused: true});
						golfboxAnim.set(loginContainer, {opacity: 1});
						golfboxAnim.to(loginContainer, 0.6, {yPercent: 100, ease: Expo.easeInOut});
						golfboxAnim.set($loginCloseButton, {opacity: 1});
						golfboxAnim.to($loginCloseButton, 0.3, {xPercent: -100, ease: Linear.easeInOut});

				$loginButton.on('click', function() {
					if (!$('body').hasClass('login-open')) {
						$('body').addClass('login-open');
						golfboxAnim.play();
					} else {
						$('body').removeClass('login-open');
						golfboxAnim.reverse();
					}
				});

				$loginCloseButton.on('click', function() {
					if ($('body').hasClass('login-open')) {
						$('body').removeClass('login-open');
						golfboxAnim.reverse();
					}
				});


				/*
				* Hover & Open mobile menu
				*/
				var mobileContainer = $('header .mobile-menu'),
						$mobileButton = $('header .mobile-menu-button__container');

				var mobileMenuOpen = new TimelineMax({paused: true});
						mobileMenuOpen.set(mobileContainer, {opacity: 1});
						mobileMenuOpen.to(mobileContainer, 0.6, {yPercent: 100, ease: Expo.easeInOut});

				$mobileButton.on('click', function() {
					if (!$('body').hasClass('mobile-open')) {
						$('body').addClass('mobile-open');
						mobileMenuOpen.play();
					} else {
						$('body').removeClass('mobile-open');
						mobileMenuOpen.reverse();
					}
				});


				/*
				* SendSmaily
				*/
				// $smly = $('#smly');

			 //  $smly.submit(function(e) {
			 //    e.preventDefault();
			 //    $.post($smly.attr('action'), $smly.serialize(), function(data) {
			 //      if (data.length) {
			 //        $smly.find('p.error').text(data).show();
			 //      }
			 //      else {
			 //        $smly.find(':not(p.success)').hide();
			 //        $smly.find('p.success').show();
			 //      }
			 //    });
			 //  });


			  /*
			  * FB share
			  */
			  window.fbAsyncInit = function() {
					FB.init({
						appId            : '984886471664766',
						autoLogAppEvents : true,
						xfbml            : true,
						version          : 'v2.12'
					});
					FB.AppEvents.logPageView();
				};

				(function(d, s, id){
					var js, fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)) {return;}
					js = d.createElement(s); js.id = id;
					js.src = "//connect.facebook.net/en_US/sdk.js";
					fjs.parentNode.insertBefore(js, fjs);
				}(document, 'script', 'facebook-jssdk'));

				function shareFBImage(image, caption, description, url) {
					FB.ui({
						method: 'share_open_graph',
						action_type: 'og.shares',
						action_properties: JSON.stringify({
							object: {
								'og:url': url,
								'og:title': caption,
								'og:description': description,
								'og:image': image
							}
						})
					}, function(response){});
				}

				$('.share-fb').on('click', function() {
					var $this       = $(this),
						image       = $this.data('image'),
						caption     = $this.data('title'),
						description = $this.data('desc'),
						url    = $this.data('href');

					shareFBImage(image, caption, description, url);
				});
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        /*
        * Header animation with GSAP
        */
		    var heroAll = $('#hero'),
		    		heroContainer = $('#hero .grid-container'),
		    		heroVideo = $('#hero .responsive-embed'),
		    		heroLayerOne = $('#hero .color-overlay.one'),
		    		heroLayerTwo = $('#hero .color-overlay.two'),
		    		heroLayerThree = $('#hero .color-overlay.three');

		    var heroAnim = new TimelineMax({paused: true});
						heroAnim.to(heroContainer, 0.4, {opacity: 1}, 0.4);
						heroAnim.to(heroVideo, 0.4, {opacity: 1}, 0.4);
		    		heroAnim.to(heroLayerOne, 0.4, {opacity: 1}, 0.4);

        setTimeout(function(){
				  $('.video').trigger('play');
			    heroAnim.play();
				}, 800);

		    /*
		    * Frontpage section load-in animations
		    */
		    $(window).load(function() {
					var sectionOneAnim = new TimelineMax({paused: true});
							// sectionOneAnim.set($('#about .section__image'), {xPercent: -100}, 0);
							// sectionOneAnim.to($('#about .section__image'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0);
							// sectionOneAnim.to($('#about .section-button'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0);
							sectionOneAnim.set($('#about .section__title .diamond'), {className: '+=animate'}, 0);
	    				sectionOneAnim.to($('#about .section__title .diamond'), 1, {rotation: '+=180', ease: Expo.easeInOut}, 1);
	      			// sectionOneAnim.to($('#about .section__title'), 0.3, {color: '#000', ease: Linear.easeInOut}, 0.6);
	      			// sectionOneAnim.to($('#about .section__title .line'), 0.3, {height: '50px', ease: Linear.easeInOut}, 0.8);
	      			// sectionOneAnim.to($('#about .section__content__inner p'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.8);

	      	var section1 = new ScrollMagic.Scene({
					    triggerElement: '#about',
					    offset: -100,
					    reverse: false
					}).setTween(sectionOneAnim)
					.addTo(controller);


					var sectionTwoAnim = new TimelineMax({paused: true});
							// sectionTwoAnim.set($('#course .section__image'), {xPercent: 100}, 0);
							// sectionTwoAnim.to($('#course .section__image'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0);
							// sectionTwoAnim.to($('#course .section-button'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0);
							sectionTwoAnim.set($('#course .section__title .diamond'), {className: '+=animate'}, 0);
							sectionTwoAnim.to($('#course .section__title .diamond'), 1, {rotation: '+=180', ease: Expo.easeInOut}, 1);
	      			// sectionTwoAnim.to($('#course .section__title'), 0.3, {color: '#000', ease: Linear.easeInOut}, 0.6);
	      			// sectionTwoAnim.to($('#course .section__title .line'), 0.3, {height: '50px', ease: Linear.easeInOut}, 0.8);
	      			// sectionTwoAnim.to($('#course .section__content__inner p'), 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.8);

	      	var section2 = new ScrollMagic.Scene({
					    triggerElement: '#course',
					    offset: -100,
					    reverse: false
					}).setTween(sectionTwoAnim)
					.addTo(controller);


					var sectionThreeAnim = new TimelineMax({paused: true});
	      			sectionThreeAnim.set($('#packages .section__title .diamond'), {className: '+=animate'}, 0);
	      			sectionThreeAnim.to($('#packages .section__title .diamond'), 1, {rotation: '+=180', ease: Expo.easeInOut}, 1);

	      	var section3 = new ScrollMagic.Scene({
					    triggerElement: '#packages',
					    offset: -50,
					    reverse: false
					}).setTween(sectionThreeAnim)
					.addTo(controller);


					var sectionFourAnim = new TimelineMax({paused: true});
	      			sectionFourAnim.set($('#news .section__title .diamond'), {className: '+=animate'}, 0);
	      			sectionFourAnim.to($('#news .section__title .diamond'), 1, {rotation: '+=180', ease: Expo.easeInOut}, 1);

	      	var section4 = new ScrollMagic.Scene({
					    triggerElement: '#news',
					    offset: -50,
					    reverse: false
					}).setTween(sectionFourAnim)
					.addTo(controller);

				});

				/*
				* Frontpage hover animations
				*/
				var aboutHoverAnim = new TimelineMax({paused: true});
						aboutHoverAnim.to($('.section#about .section__image'), 10, {scale: 1.05, rotation: 0.01, ease: Linear.easeInOut, force3D:false}, 0);

				$('#about').on({
			    mouseenter: function () {
			      aboutHoverAnim.play();
			    },
			    mouseleave: function () {
			      aboutHoverAnim.reverse();
			    }
				});

				var courseHoverAnim = new TimelineMax({paused: true});
						courseHoverAnim.to($('.section#course .section__image'), 10, {scale: 1.05, rotation: 0.01, ease: Linear.easeInOut, force3D:false}, 0);

				$('#course').on({
			    mouseenter: function () {
			      courseHoverAnim.play();
			    },
			    mouseleave: function () {
			      courseHoverAnim.reverse();
			    }
				});

				var allPackages = $('.packages .packages__item').length,
						packageWidth = 100 / allPackages,
						growSize;

				if (allPackages > 3) {
					growSize = 8;
				} else {
					growSize = 5;
				}

				$('.packages .packages__item').css('min-width', packageWidth + '%');

				$('.packages .packages__item').each(function() {
					$(this).on({
						mouseenter: function () {
							$(this).addClass('package-expand');
			        $('.packages .packages__item').not(this).addClass('package-shrink');

			        $('.packages .packages__item.package-expand').css('min-width', packageWidth + growSize + '%');
			        $('.packages .packages__item.package-shrink').css('min-width', packageWidth - growSize + '%');
				    },
				    mouseleave: function () {
				    	$(this).removeClass('package-expand');
			        $('.packages .packages__item').not(this).removeClass('package-shrink');

			        $('.packages .packages__item').css('min-width', packageWidth + '%');
				    }
					});
				});

				/*
				* Overlay video animation
				*/
				var $videoCloseButton = $('.wrap.container .close-button--video');
				var overlayVideo 	  = $('#overlay-video');

				var overlayVideoAnim = new TimelineMax({paused: true});
						overlayVideoAnim.to(overlayVideo, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						overlayVideoAnim.set($videoCloseButton, {css:{zIndex: 4, display: 'block'}}, 0.8);
						overlayVideoAnim.to($videoCloseButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				var player = plyr.setup('.plyr-video');

				$('.watch-video').on('click', function() {
					player[0].play();
					
					if ($('body').hasClass('video-open')) {
						overlayVideoAnim.reverse();
						$('body').removeClass('video-open');
					} else {
						$('body').addClass('video-open');
						overlayVideoAnim.play();
					}
				});

				$videoCloseButton.on('click', function() {
					if ($('body').hasClass('video-open')) {
						overlayVideoAnim.reverse();
						player[0].pause();
						$('body').removeClass('video-open');
					}
				});

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Packages page
    page_template_template_packages: {
    	init: function() {
    		/*
    		* Package page Slick slider
    		*/
    		$('.slider').each(function() {
				  $(this).slick({
					  infinite: false,
					  dots: false,
						prevArrow: '<button type="button" class="slick-prev"></button>',
					  nextArrow: '<button type="button" class="slick-next"></button>'
					});
				});

				/*
		    * Package title Select2 dropdown for smaller screens
		    */
		    $('.package-page .package-select-box').select2({
		    	minimumResultsForSearch: Infinity,
		    	dropdownCssClass: 'package-dropdown'
		    });

		    $('.package-page .package-select-box').on('select2:select', function(e) {
		    	$selectedValue = $(this).find(':selected')[0].dataset.clickValue;

		    	$('#package-tabs').find($('#' + $selectedValue + '-label')).click();
		    });

		    if(window.location.hash) {
				  var hash = window.location.hash.substring(1);

				  $('.package-page .package-select-box').val(hash);
				  $('.package-page .package-select-box').trigger('change');
				}

				/*
				* Page load and package change animations
				*/
				var overlayOne = $('.tabs-content .color-overlay.one'),
		    		overlayTwo = $('.tabs-content .color-overlay.two'),
		    		packageItem = $('.tabs-content .package-page__item'),
		    		packageSide = $('.package-page .tabs');

				var overlayAnim = new TimelineMax({paused: true});
						overlayAnim.to(packageItem, 0.25, {opacity: 0}, 0);
						overlayAnim.to(packageItem, 0.25, {opacity: 1}, 0.3);

				var sideAnim = new TimelineMax({paused: true});
						sideAnim.to(packageSide, 1.4, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.3);

				window.addEventListener("load",function(event) {
					sideAnim.play();

					setTimeout(function(){
					  overlayAnim.play(0);
					}, 1000);
				}, false);

				$('.tabs').on('change.zf.tabs', function () {
					var tabId = $('div[data-tabs-content="'+$(this).attr('id')+'"]').find('.tabs-panel.is-active').attr('id');

					$('.package-page .package-select-box').val(tabId);
				  $('.package-page .package-select-box').trigger('change');

					overlayAnim.play(0);
			    $('.slider').slick('setPosition');
				});

				/*
				* Form open & close animation
				*/
				var formOuter = $('.form'),
		    		formInner = $('.form__container'),
		    		formClose = $('.form .form__close .close-button');

				var formAnim = new TimelineMax({paused: true});
						formAnim.set(formOuter, {css:{zIndex: 1000, overflowY: 'auto'}}, 0);
						formAnim.to(formOuter, 0.2, {opacity: 1, ease: Linear.easeNone}, 0.1);
						formAnim.to(formInner, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.1);
						formAnim.set(formClose, {opacity: 1});
						formAnim.to(formClose, 0.3, {xPercent: 100, ease: Linear.easeInOut});

				$('.package-page__item__order').on('click', function() {
					if ($('body').hasClass('form-open')) {
						formAnim.reverse();
						$('body').removeClass('form-open');
					} else {
						var packageTitle = $(this).parent().parent().find('.package-page__item__title .text').html();

						// $('.form #package-select').val(packageTitle).trigger('change.select2');

						$('body').addClass('form-open');
						formAnim.play();
					}
				});

				window.addEventListener('resize', function(event){
				  formAnim.reverse();
					$('body').removeClass('form-open');
				});

				if (sessionStorage) {
			    var openForm = sessionStorage.getItem('openPackagesForm');

			    if (openForm === 'true') {
			    	$('body').addClass('form-open');
						formAnim.play();
			    }

			    sessionStorage.setItem('openPackagesForm', 'false');
				}

				$('.form .grid-x').click(function(e) {
        	if (e.target === this){
	        	if ($('body').hasClass('form-open')) {
		        	formAnim.reverse();
							$('body').removeClass('form-open');
							
							setTimeout(function(){
							  formOuter.scrollTop(0);
							  $(".form form").trigger('reset');
							  $('input.input__field').parent().removeClass('input--filled');

							  var firstVal = $('.tee-time select option:first-child').val();
							  $('.form select').val(firstVal).trigger('change.select2');
							}, 900);
						}
					}
				});

				$('.form .close-button').click(function() {
        	if ($('body').hasClass('form-open')) {
	        	formAnim.reverse();
						$('body').removeClass('form-open');

						setTimeout(function(){
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');

						  var firstVal = $('.tee-time select option:first-child').val();
							$('.form select').val(firstVal).trigger('change.select2');
						}, 900);
					}
				});

				/*
				* Form send button scroll
				*/
				$('.form input[type="submit"]').on('click', function() {
					formOuter.scrollTop(formOuter[0].scrollHeight);
				});

				/*
				* Form unsuccesful submit
				*/
				document.addEventListener('wpcf7invalid', function(event) {
				  formOuter.scrollTop(0);
				}, false );

				$( function() {
					$( "#date-from" ).datepicker({ minDate: 0 });
					$( "#date-to" ).datepicker({ minDate: 0 });
				} );

				/*
				* Form success animation
				*/
				var wpcf7Elm = document.querySelector('.wpcf7'),
						formTitle = $('.form .form__title'),
						formText = $('.form .form__text'),
						formElem = $('.wpcf7-form-control-wrap'),
						formMessage = $('.wpcf7-response-output'),
						formSubmit = $('.wpcf7-submit');

				var formSuccess = new TimelineMax({paused: true});
						formSuccess.to(formText, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formElem, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formSubmit, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.set(formText, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formElem, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formSubmit, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formMessage, {css:{display: 'block !important'}}, 0.4);
						formSuccess.to(formMessage, 0.4, {opacity: 1}, 0.5);
 
				document.addEventListener('wpcf7mailsent', function(event) {
				  formSuccess.play();

				  setTimeout(function() {
				  	formAnim.reverse();
				  	$('body').removeClass('form-open');

						setTimeout(function(){
							formSuccess.reverse();
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');

						  var firstVal = $('.tee-time select option:first-child').val();
							$('.form select').val(firstVal).trigger('change.select2');
						}, 900);
				  }, 3000);
				}, false );

				/*
				* WPCF7 date range function
				*/
				var start = $('#date-from').first(),
				    end = $('#date-to').first();

		    start.on('change', function() {
		      var start_date = $(this).datepicker('getDate');
		      start_date.setDate(start_date.getDate() + 1);
		      end.datepicker('option', 'minDate', start_date);
		    });

		    /*
		    * WPCF7 Select2 dropdown
		    */
		    $('.form select').select2({
		    	minimumResultsForSearch: Infinity
		    });

    	}
    },
    // Golf page
    page_template_template_golf: {
    	init: function() {
    		/*
				* Page load animation
				*/
				var pageContent = $('#golf .golf'),
		    		side = $('#golf .golf__side'),
		    		secondaryMenu = $('#golf .button-container');

				var overlayAnim = new TimelineMax({paused: true});
						overlayAnim.to(pageContent, 0.25, {opacity: 1}, 0.3);

				var sideAnim = new TimelineMax({paused: true});
						sideAnim.to(secondaryMenu, 0.4, {css:{transform: 'translateY(0%)'}, ease: Linear.easeInOut}, 0.3);
						sideAnim.to(side, 1.4, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.3);

				window.addEventListener("load",function(event) {
					sideAnim.play();

					setTimeout(function(){
					  overlayAnim.play(0);
					}, 1000);
				}, false);

    		/*
    		* Slick slider
    		*/
    		$('.slider').each(function() {
				  $(this).slick({
					  infinite: false,
					  dots: false,
						prevArrow: '<button type="button" class="slick-prev"></button>',
					  nextArrow: '<button type="button" class="slick-next"></button>'
					});
				});

				/*
				* Course pricelist animation
				*/
				var $closeButton = $('.wrap.container .close-button');

				var table = $('#golf__table');

				var tableAnim = new TimelineMax({paused: true});
						tableAnim.to(table, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						tableAnim.set($closeButton, {css:{zIndex: 3, display: 'block'}}, 0.8);
						tableAnim.to($closeButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				$('.button-container__button.pricelist').on('click', function() {
					if ($('body').hasClass('table-open')) {
						tableAnim.reverse();
						$('body').removeClass('table-open');
					} else {
						$('body').addClass('table-open');
						tableAnim.play();
					}
				});


				/*
				* Rules animation
				*/
				var rules = $('#golf__rules');

				var rulesAnim = new TimelineMax({paused: true});
						rulesAnim.to(rules, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						rulesAnim.set($closeButton, {css:{zIndex: 3, display: 'block'}}, 0.8);
						rulesAnim.to($closeButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				$('.button-container__button.rules').on('click', function() {
					if ($('body').hasClass('rules-open')) {
						rulesAnim.reverse();
						$('body').removeClass('rules-open');
					} else {
						$('body').addClass('rules-open');
						rulesAnim.play();
					}
				});


				/*
				* Hole-in-one animation
				*/
				var hole = $('#golf__hole-in-one');

				var holeAnim = new TimelineMax({paused: true});
						holeAnim.to(hole, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						holeAnim.set($closeButton, {css:{zIndex: 3, display: 'block'}}, 0.8);
						holeAnim.to($closeButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				$('.button-container__button.hole-in-one').on('click', function() {
					if ($('body').hasClass('hole-open')) {
						holeAnim.reverse();
						$('body').removeClass('hole-open');
					} else {
						$('body').addClass('hole-open');
						holeAnim.play();
					}
				});

				$closeButton.on('click', function() {
					if ($('body').hasClass('table-open')) {
						tableAnim.reverse();
						$('body').removeClass('table-open');
					}
					if ($('body').hasClass('rules-open')) {
						rulesAnim.reverse();
						$('body').removeClass('rules-open');
					}
					if ($('body').hasClass('hole-open')) {
						holeAnim.reverse();
						$('body').removeClass('hole-open');
					}
				});

			}
		},
		// Courses page
    page_template_template_courses: {
    	init: function() {
    		/*
				* Page load animation
				*/
				var side = $('#courses .trails');

				var sideAnim = new TimelineMax({paused: true});
						sideAnim.to(side, 1.4, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.3);

				window.addEventListener("load",function(event) {
					sideAnim.play();
				}, false);

    		/*
        * Trails desktop animation global vars and functions
        */
	      var map = document.getElementById('trails'),
	      		svgCont = map.getElementById('svg-cont'),
      			mapWidth = map.viewBox.baseVal.width,
      			mapHeight = map.viewBox.baseVal.height,
      			mapX = mapWidth / 2,
      			mapY = mapHeight / 2,
      			trailList = $('#courses .trails'),
      			$trailsName = $('.trails__name'),
      			$trailName = $('.trail__content'),
						zooms = [3, 5, 3, 5, 4, 4, 3, 4, 4, 3, 5, 3, 4, 5, 4, 4, 5, 3],
						trailBackgrounds = map.querySelectorAll('.trail-bg'),
						trailDatas = map.getElementsByClassName('trail-data'),
						trailLines = map.getElementsByClassName('trail-line'),
        		trailTitles = map.getElementsByClassName('trail-title'),
        		walking = map.getElementById('walking'),
      			clubHouse = map.getElementById('clubhouse-and-parking'),
      			forest = map.getElementById('forest'),
      			otherBackground = map.getElementById('bg-random'),
      			par3 = map.getElementById('par-3'),
      			drivingRange = map.getElementById('driving-range'),
      			trailOuter = $('.trail'),
		    		trailInner = $('.trail__container'),
		    		trailClose = $('.trail .trail__close .close-button');

		    var trailInAnim = new TimelineMax({paused: true});
						trailInAnim.set(trailOuter, {css:{zIndex: 1, overflowY: 'auto'}}, 0);
						trailInAnim.to(trailOuter, 0.2, {opacity: 1, ease: Linear.easeNone}, 0.1);
						trailInAnim.to(trailInner, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.1);
						trailInAnim.set(trailClose, {opacity: 1});
						trailInAnim.to(trailClose, 0.3, {xPercent: -100, ease: Linear.easeInOut});

				var trailListAnim = new TimelineMax({paused: true});
						trailListAnim.to(trailList, 0.8, {xPercent: -100, opacity: 0, ease: Expo.easeInOut}, 0);
						trailListAnim.set(trailList, {opacity: 0});

				function viewTrail(trailID) {
      		map.getElementById('trail-' + trailID + '-bg').classList.add('active');

        	var trail = map.getElementById('trail-' + trailID + '-bg'),
        			trailData = map.getElementById('trail-' + trailID + '-data'),
        			trailLine = map.getElementById('trail-' + trailID + '-line'),
        			trailBackgrounds = map.querySelectorAll('.trail-bg:not(.active)'),
	      			trailX = trail.getBBox().x,
	      			trailY = trail.getBBox().y,
	      			trailWidth = trail.getBBox().width,
	      			trailHeight = trail.getBBox().height,
							mapMoveX = mapX - (trailX + (trailWidth / 2)),
	      			mapMoveY = mapY - (trailY + (trailHeight / 2));

	      	var mapZoom = new TimelineMax({paused: true});
	      			mapZoom.set(drivingRange, {opacity: 0.3}, 0);
	      			mapZoom.set(par3, {opacity: 0.3}, 0);
	      			mapZoom.set(otherBackground, {opacity: 0.3}, 0);
	      			mapZoom.set(clubHouse, {opacity: 0.3}, 0);
	      			mapZoom.set(walking, {opacity: 0.3}, 0);
	      			mapZoom.set(trailLines, {autoAlpha: 0}, 0);
	      			mapZoom.set(trailLine, {autoAlpha: 1}, 0);
	      			mapZoom.set(trailLine, {drawSVG: '0%'}, 0);
	      			mapZoom.set(trailTitles, {autoAlpha: 0}, 0);
	      			mapZoom.to(forest, 0.2, {opacity: 0.3}, 0);
	      			mapZoom.to(trailBackgrounds, 0.3, {opacity: 0.3}, 0);
	      			mapZoom.to(trailData, 0.3, {opacity: 1}, 0.3);
		      		mapZoom.to(svgCont, 0.8, {x: mapMoveX, y: mapMoveY, ease: Linear.easeInOut}, 0);
		      		mapZoom.to(map, 0.8, {scale: zooms[trailID - 1], ease: Linear.easeInOut}, 0);
		      		mapZoom.to(trailLine, 0.8, {drawSVG: '100%'});

	    		mapZoom.play();
        }
        function moveToTrail(trailID) {
					document.getElementById('trails').getElementsByClassName('trail-bg active')[0].classList.remove('active');
      		map.getElementById('trail-' + trailID + '-bg').classList.add('active');

        	var trail = map.getElementById('trail-' + trailID + '-bg'),
        			trailData = map.getElementById('trail-' + trailID + '-data'),
        			trailLine = map.getElementById('trail-' + trailID + '-line'),
        			trailBackground = map.getElementsByClassName('trail-bg active'),
        			trailBackgrounds = map.querySelectorAll('.trail-bg:not(.active)'),
	      			trailX = trail.getBBox().x,
	      			trailY = trail.getBBox().y,
	      			trailWidth = trail.getBBox().width,
	      			trailHeight = trail.getBBox().height;

	      	var mapMoveX = mapX - (trailX + (trailWidth / 2)),
	      			mapMoveY = mapY - (trailY + (trailHeight / 2));

	      	var mapZoom = new TimelineMax({paused: true});
	      			mapZoom.set(trailLines, {autoAlpha: 0}, 0);
	      			mapZoom.set(trailLine, {autoAlpha: 1}, 0);
	      			mapZoom.set(trailLine, {drawSVG: '0%'}, 0);
	      			mapZoom.set(trailBackgrounds, {opacity: 0.3}, 0);
	      			mapZoom.to(trailBackground, 0.3, {opacity: 1}, 0.1);
	      			mapZoom.to(trailDatas, 0.3, {opacity: 0}, 0);
	      			mapZoom.to(trailData, 0.3, {opacity: 1}, 0.3);
		      		mapZoom.to(svgCont, 0.8, {x: mapMoveX, y: mapMoveY, ease: Linear.easeInOut}, 0);
		      		mapZoom.to(map, 0.8, {scale: zooms[trailID - 1], ease: Linear.easeInOut}, 0);
		      		mapZoom.to(trailLine, 0.8, {drawSVG: '100%'});

	    		mapZoom.play();
        }
        function mapZoomOutFunction() {
        	var mapZoomOut = new TimelineMax({paused: true});
		      		mapZoomOut.to(svgCont, 1, {x: 0, y: 0, ease: Linear.easeInOut}, 0);
		      		mapZoomOut.to(map, 1, {scale: 1, ease: Linear.easeInOut}, "-=1");
		      		mapZoomOut.set(drivingRange, {opacity: 1}, 0);
	      			mapZoomOut.set(par3, {opacity: 1}, 0);
	      			mapZoomOut.set(otherBackground, {opacity: 1}, 0);
	      			mapZoomOut.set(clubHouse, {opacity: 1}, 0);
	      			mapZoomOut.set(walking, {opacity: 1}, 0);
	      			mapZoomOut.set(trailLines, {autoAlpha: 1}, 0);
	      			mapZoomOut.set(trailTitles, {autoAlpha: 1}, 0);
	      			mapZoomOut.to(forest, 0.2, {opacity: 1}, 0);
	      			mapZoomOut.to(trailBackgrounds, 0.3, {opacity: 1}, 0);
	      			mapZoomOut.to(trailDatas, 0.3, {opacity: 0}, 0);

	      	mapZoomOut.play();
        }
        // End of desktop functions

        /*
        * Trails mobile animation global vars and functions
        */
        var mobileMap = document.getElementById('trails-mobile'),
        		mobileSvgCont = mobileMap.getElementById('svg-cont-mobile'),
      			mobileMapWidth = mobileMap.viewBox.baseVal.width,
      			mobileMapHeight = mobileMap.viewBox.baseVal.height,
      			mobileMapX = mobileMapWidth / 2,
      			mobileMapY = mobileMapHeight / 2,
      			$mobileTrailsName = $('.trails__name'),
      			mobileZooms = [3, 5, 3, 5, 4, 4, 3, 4, 4, 3, 5, 3, 4, 5, 4, 4, 5, 3],
      			mobileTrailDatas = mobileMap.getElementsByClassName('trail-data'),
      			mobileTrailLines = mobileMap.getElementsByClassName('trail-line'),
        		mobileTrailTitles = mobileMap.getElementsByClassName('trail-title'),
        		mobileTrailBackgrounds = mobileMap.querySelectorAll('.trail-bg'),
        		mobileWalking = mobileMap.getElementById('walking-mobile'),
      			mobileClubHouse = mobileMap.getElementById('clubhouse-and-parking-mobile'),
      			mobileForest = mobileMap.getElementById('forest-mobile'),
      			mobileOtherBackground = mobileMap.getElementById('bg-random-mobile'),
      			mobilePar3 = mobileMap.getElementById('par-3-mobile'),
      			mobileDrivingRange = mobileMap.getElementById('driving-range-mobile');

      	function mobileViewTrail(mobileTrailID) {
      		mobileMap.getElementById('trail-' + mobileTrailID + '-bg-mobile').classList.add('active');

        	var mobileTrail = mobileMap.getElementById('trail-' + mobileTrailID + '-bg-mobile'),
        			mobileTrailData = mobileMap.getElementById('trail-' + mobileTrailID + '-data-mobile'),
        			mobileTrailLine = mobileMap.getElementById('trail-' + mobileTrailID + '-line-mobile'),
        			mobileTrailBackgrounds = mobileMap.querySelectorAll('.trail-bg:not(.active)'),
	      			mobileTrailX = mobileTrail.getBBox().x,
	      			mobileTrailY = mobileTrail.getBBox().y,
	      			mobileTrailWidth = mobileTrail.getBBox().width,
	      			mobileTrailHeight = mobileTrail.getBBox().height,
	      			mobileMapMoveX = mobileMapX - (mobileTrailX + (mobileTrailWidth / 2)),
	      			mobileMapMoveY = mobileMapY - (mobileTrailY + (mobileTrailHeight / 2));

	      	var mobileMapZoom = new TimelineMax({paused: true});
	      			mobileMapZoom.set(mobileDrivingRange, {opacity: 0.3}, 0);
	      			mobileMapZoom.set(mobilePar3, {opacity: 0.3}, 0);
	      			mobileMapZoom.set(mobileOtherBackground, {opacity: 0.3}, 0);
	      			mobileMapZoom.set(mobileClubHouse, {opacity: 0.3}, 0);
	      			mobileMapZoom.set(mobileWalking, {opacity: 0.3}, 0);
	      			mobileMapZoom.set(mobileTrailLines, {autoAlpha: 0}, 0);
	      			mobileMapZoom.set(mobileTrailLine, {autoAlpha: 1}, 0);
	      			mobileMapZoom.set(mobileTrailLine, {drawSVG: '0%'}, 0);
	      			mobileMapZoom.set(mobileTrailTitles, {autoAlpha: 0}, 0);
	      			mobileMapZoom.to(mobileForest, 0.2, {opacity: 0.3}, 0);
	      			mobileMapZoom.to(mobileTrailBackgrounds, 0.3, {opacity: 0.3}, 0);
	      			mobileMapZoom.to(mobileTrailData, 0.3, {opacity: 1}, 0.3);
		      		mobileMapZoom.to(mobileSvgCont, 0.8, {x: mobileMapMoveX, y: mobileMapMoveY, ease: Linear.easeInOut}, 0);
		      		mobileMapZoom.to(mobileMap, 0.8, {scale: mobileZooms[mobileTrailID - 1], ease: Linear.easeInOut}, 0);
		      		mobileMapZoom.to(mobileTrailLine, 0.8, {drawSVG: '100%'});

	    		mobileMapZoom.play();
        }
        function mobileMoveToTrail(mobileTrailID) {
					document.getElementById('trails-mobile').getElementsByClassName('trail-bg active')[0].classList.remove('active');
      		mobileMap.getElementById('trail-' + mobileTrailID + '-bg-mobile').classList.add('active');

        	var mobileTrail = mobileMap.getElementById('trail-' + mobileTrailID + '-bg-mobile'),
        			mobileTrailData = mobileMap.getElementById('trail-' + mobileTrailID + '-data-mobile'),
        			mobileTrailLine = mobileMap.getElementById('trail-' + mobileTrailID + '-line-mobile'),
        			mobileTrailBackground = mobileMap.getElementsByClassName('trail-bg active'),
        			mobileTrailBackgrounds = mobileMap.querySelectorAll('.trail-bg:not(.active)'),
	      			mobileTrailX = mobileTrail.getBBox().x,
	      			mobileTrailY = mobileTrail.getBBox().y,
	      			mobileTrailWidth = mobileTrail.getBBox().width,
	      			mobileTrailHeight = mobileTrail.getBBox().height;

	      	var mobileMapMoveX = mobileMapX - (mobileTrailX + (mobileTrailWidth / 2)),
	      			mobileMapMoveY = mobileMapY - (mobileTrailY + (mobileTrailHeight / 2));

	      	var mobileMapZoom = new TimelineMax({paused: true});
	      			mobileMapZoom.set(mobileTrailLines, {autoAlpha: 0}, 0);
	      			mobileMapZoom.set(mobileTrailLine, {autoAlpha: 1}, 0);
	      			mobileMapZoom.set(mobileTrailLine, {drawSVG: '0%'}, 0);
	      			mobileMapZoom.set(mobileTrailBackgrounds, {opacity: 0.3}, 0);
	      			mobileMapZoom.to(mobileTrailBackground, 0.3, {opacity: 1}, 0.1);
	      			mobileMapZoom.to(mobileTrailDatas, 0.3, {opacity: 0}, 0);
	      			mobileMapZoom.to(mobileTrailData, 0.3, {opacity: 1}, 0.3);
		      		mobileMapZoom.to(mobileSvgCont, 0.8, {x: mobileMapMoveX, y: mobileMapMoveY, ease: Linear.easeInOut}, 0);
		      		mobileMapZoom.to(mobileMap, 0.8, {scale: mobileZooms[mobileTrailID - 1], ease: Linear.easeInOut}, 0);
		      		mobileMapZoom.to(mobileTrailLine, 0.8, {drawSVG: '100%'});

	    		mobileMapZoom.play();
        }
        function mobileMapZoomOutFunction() {
        	var mobileMapZoomOut = new TimelineMax({paused: true});
		      		mobileMapZoomOut.to(mobileSvgCont, 1, {x: 0, y: 0, ease: Linear.easeInOut}, 0);
		      		mobileMapZoomOut.to(mobileMap, 1, {scale: 1, ease: Linear.easeInOut}, "-=1");
		      		mobileMapZoomOut.set(mobileDrivingRange, {opacity: 1}, 0);
	      			mobileMapZoomOut.set(mobilePar3, {opacity: 1}, 0);
	      			mobileMapZoomOut.set(mobileOtherBackground, {opacity: 1}, 0);
	      			mobileMapZoomOut.set(mobileClubHouse, {opacity: 1}, 0);
	      			mobileMapZoomOut.set(mobileWalking, {opacity: 1}, 0);
	      			mobileMapZoomOut.set(mobileTrailLines, {autoAlpha: 1}, 0);
	      			mobileMapZoomOut.set(mobileTrailTitles, {autoAlpha: 1}, 0);
	      			mobileMapZoomOut.to(mobileForest, 0.2, {opacity: 1}, 0);
	      			mobileMapZoomOut.to(mobileTrailBackgrounds, 0.3, {opacity: 1}, 0);
	      			mobileMapZoomOut.to(mobileTrailDatas, 0.3, {opacity: 0}, 0);

	      	mobileMapZoomOut.play();
        }
        // End of mobile functions


    		function responsiveMapAnimations() {
	    		if (Foundation.MediaQuery.atLeast('large')) {
						/*
	    			* Map title hover effect and click action
	    			*/
		      	$trailsName.each(function() {
		      		var trailHoverID = $(this).data('trail'),
			      			trailTitle = map.getElementById('trail-' + trailHoverID + '-title');

			      	var mapHover = new TimelineMax({paused: true});
				      		mapHover.to(trailTitle, 0.6, {scale: 2, transformOrigin: 'center'});

			      	if (!$('body').hasClass('map-zoomed')) {
				      	$(this).on({
							    mouseenter: function () {
								    mapHover.play();
							    },
							    mouseleave: function () {
							    	mapHover.reverse();
							    }
								});

								$(this).on('click', function() {
									if (Foundation.MediaQuery.atLeast('large')) {
										window.scrollTo(0,0);

										mapHover.reverse();

						      	var trailID = $(this).data('trail');

						      	$('.trail .trail__item-' + trailID).addClass('active');
						      	$('body').addClass('trail-open');
						      	$('.trails__name').removeClass('active');
					      		$(this).addClass('active');
					      		$('body').addClass('map-zoomed');

						      	viewTrail(trailID);
						      	trailInAnim.play();
						      }
				      	});
							}
						});

						/*
						* Scroll body when in single view
						*/
						var target = $('html');

				    $('.trail').scroll(function() {
				      if ($('body').hasClass('trail-open')) {
				        target.prop('scrollTop', this.scrollTop);
				      }
				    });

				    /*
				    * Single view data hover animations
				    */
						$trailName.each(function() {
		      		var trailHoverID = $(this).data('trail'),
		      				pikeTitle = map.getElementById('trail-' + trailHoverID + '-data').getElementsByClassName('pike'),
			      			breamTitle = map.getElementById('trail-' + trailHoverID + '-data').getElementsByClassName('bream'),
			      			perchTitle = map.getElementById('trail-' + trailHoverID + '-data').getElementsByClassName('perch'),
			      			burbotTitle = map.getElementById('trail-' + trailHoverID + '-data').getElementsByClassName('burbot');

			      	$(this).find('.pike').on({
						    mouseenter: function () {
						    	if ($('body').hasClass('map-zoomed')) {
							      map.getElementById('trail-' + trailHoverID + '-data').classList.add('pike-active');
							    }
						    },
						    mouseleave: function () {
						    	map.getElementById('trail-' + trailHoverID + '-data').classList.remove('pike-active');
						    }
							});
							$(this).find('.bream').on({
						    mouseenter: function () {
						    	if ($('body').hasClass('map-zoomed')) {
							      map.getElementById('trail-' + trailHoverID + '-data').classList.add('bream-active');
							    }
						    },
						    mouseleave: function () {
						    	map.getElementById('trail-' + trailHoverID + '-data').classList.remove('bream-active');
						    }
							});
							$(this).find('.perch').on({
						    mouseenter: function () {
						    	if ($('body').hasClass('map-zoomed')) {
							      map.getElementById('trail-' + trailHoverID + '-data').classList.add('perch-active');
							    }
						    },
						    mouseleave: function () {
						    	map.getElementById('trail-' + trailHoverID + '-data').classList.remove('perch-active');
						    }
							});
							$(this).find('.burbot').on({
						    mouseenter: function () {
						    	if ($('body').hasClass('map-zoomed')) {
							      map.getElementById('trail-' + trailHoverID + '-data').classList.add('burbot-active');
							    }
						    },
						    mouseleave: function () {
						    	map.getElementById('trail-' + trailHoverID + '-data').classList.remove('burbot-active');
						    }
							});
						});

						$('.trail .trail__close .close-button.desktop').on('click', function() {
		        	if ($('body').hasClass('trail-open')) {
			        	trailInAnim.reverse();
								$('body').removeClass('trail-open');

								setTimeout(function(){
								  $('.trail .trail__item').removeClass('active');
								}, 900);

								$('.trails__name').removeClass('active');
			      		$('body').removeClass('map-zoomed');

			      		document.getElementById('trails').getElementsByClassName('trail-bg active')[0].classList.remove('active');

			      		mapZoomOutFunction();
							}
						});

						$('.trail .trail__name .prev.desktop').on('click', function() {
							var trailID = $(this).parent().data('id');

							$('.trail .trail__item').removeClass('active');

							if (trailID === 1) {
								trailID = 18;
							} else {
								trailID = trailID - 1;
							}

							moveToTrail(trailID);
							$('.trail .trail__item-' + trailID).addClass('active');
						});

						$('.trail .trail__name .next.desktop').on('click', function() {
							var trailID = $(this).parent().data('id');

							$('.trail .trail__item').removeClass('active');

							if (trailID === 18) {
								trailID = 1;
							} else {
								trailID = trailID + 1;
							}

							moveToTrail(trailID);
							$('.trail .trail__item-' + trailID).addClass('active');
						});


					} else {
						/*
		        * Map title click action
		        */
		        if (Foundation.MediaQuery.is('small only')) {
			        $('.mobile.map').css({ height: window.innerHeight - 140 });
			      } else {
			      	$('.mobile.map').css({ height: 'auto' });
			      }

		      	$mobileTrailsName.each(function() {
			      	if (!$('body').hasClass('map-zoomed')) {
				      	$(this).on('click', function() {
				      		if (!Foundation.MediaQuery.atLeast('large')) {
										window.scrollTo(0,0);

						      	var mobileTrailID = $(this).data('trail');

						      	$('.trail .trail__item-' + mobileTrailID).addClass('active');
						      	$('body').addClass('trail-open');
						      	$('.trails__name').removeClass('active');
					      		$(this).addClass('active');
					      		$('body').addClass('map-zoomed');

					      		if (Foundation.MediaQuery.is('small only')) {
						      		trailListAnim.play();
						      	}

						      	mobileViewTrail(mobileTrailID);
						      	trailInAnim.play();
						      }
				      	});
							}
						});

						$('.trail .trail__close .close-button.mobile').on('click', function() {
		        	if ($('body').hasClass('trail-open')) {
			        	trailInAnim.reverse();

			        	if (Foundation.MediaQuery.is('small only')) {
				      		trailListAnim.reverse();
				      	}

								$('body').removeClass('trail-open');

								setTimeout(function(){
								  $('.trail .trail__item').removeClass('active');
								}, 900);

								$('.trails__name').removeClass('active');
			      		$('body').removeClass('map-zoomed');

			      		document.getElementById('trails-mobile').getElementsByClassName('trail-bg active')[0].classList.remove('active');

			      		mobileMapZoomOutFunction();
							}
						});

						$('.trail .trail__name .prev.mobile').on('click', function() {
							var mobileTrailID = $(this).parent().data('id');

							$('.trail .trail__item').removeClass('active');

							if (mobileTrailID === 1) {
								mobileTrailID = 18;
							} else {
								mobileTrailID = mobileTrailID - 1;
							}

							mobileMoveToTrail(mobileTrailID);
							$('.trail .trail__item-' + mobileTrailID).addClass('active');
						});

						$('.trail .trail__name .next.mobile').on('click', function() {
							var mobileTrailID = $(this).parent().data('id');

							$('.trail .trail__item').removeClass('active');

							if (mobileTrailID === 18) {
								mobileTrailID = 1;
							} else {
								mobileTrailID = mobileTrailID + 1;
							}

							mobileMoveToTrail(mobileTrailID);
							$('.trail .trail__item-' + mobileTrailID).addClass('active');
						});

					}  // End of media query else
				}

				$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
					responsiveMapAnimations();
				});

				responsiveMapAnimations();

				$(window).on('resize', function() {
					if (Foundation.MediaQuery.is('small only')) {
		        $('.mobile.map').css({ height: window.innerHeight - 140 });
		      } else {
		      	$('.mobile.map').css({ height: 'auto' });
		      }

					if ($('body').hasClass('trail-open')) {
						if (Foundation.MediaQuery.atLeast('large')) {
		        	trailInAnim.reverse();
							$('body').removeClass('trail-open');

							setTimeout(function(){
							  $('.trail .trail__item').removeClass('active');
							}, 900);

							$('.trails__name').removeClass('active');
		      		$('body').removeClass('map-zoomed');

		      		document.getElementById('trails').getElementsByClassName('trail-bg active')[0].classList.remove('active');

		      		mapZoomOutFunction();
		      	} else {
		      		if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			        	trailInAnim.reverse();

			        	if (Foundation.MediaQuery.is('small only')) {
				      		trailListAnim.reverse();
				      	}

								$('body').removeClass('trail-open');

								setTimeout(function(){
								  $('.trail .trail__item').removeClass('active');
								}, 900);

								$('.trails__name').removeClass('active');
			      		$('body').removeClass('map-zoomed');

			      		document.getElementById('trails-mobile').getElementsByClassName('trail-bg active')[0].classList.remove('active');

			      		mobileMapZoomOutFunction();
							}
		      	}
					}
				});

    	}
    },
		page_template_template_golf_trainer: {
			init: function() {
				/*
				* Page load animation
				*/
				var pageContent = $('#restaurant .restaurant'),
					side = $('#restaurant .side');

				var overlayAnim = new TimelineMax({paused: true});
						overlayAnim.to(pageContent, 0.25, {opacity: 1}, 0.3);

				var sideAnim = new TimelineMax({paused: true});
						sideAnim.to(side, 1.4, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.3);

				window.addEventListener("load",function(event) {
					sideAnim.play();

					setTimeout(function(){
					overlayAnim.play(0);
					}, 1000);
				}, false);

				/*
			* Slick slider
			*/
			$('.slider').each(function() {
				$(this).slick({
					infinite: false,
					dots: false,
						prevArrow: '<button type="button" class="slick-prev"></button>',
					nextArrow: '<button type="button" class="slick-next"></button>'
					});
				});

				/*
				* Food menu animation
				*/
				var closeButton = $('.close-button');

				var table = $('#food-menu');

				var tableAnim = new TimelineMax({paused: true});
						tableAnim.to(table, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						tableAnim.set(closeButton, {css:{zIndex: 3, display: 'block'}}, 0.8);
						tableAnim.to(closeButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				$('.button-menu').on('click', function() {
					if ($('body').hasClass('food-menu-open')) {
						tableAnim.reverse();
						$('body').removeClass('food-menu-open');
					} else {
						$('body').addClass('food-menu-open');
						tableAnim.play();
					}
				});

				$('.close-button').on('click', function() {
					if ($('body').hasClass('food-menu-open')) {
						tableAnim.reverse();
						$('body').removeClass('food-menu-open');
					}
				});

				/*
			* WPCF7 Select2 dropdown
			*/
			$('.form select').select2({
				minimumResultsForSearch: Infinity
			});


				/*
				* Form open & close animation
				*/
				var formOuter = $('.form'),
					formInner = $('.form__container'),
					formClose = $('.form .form__close .close-button');

				var formAnim = new TimelineMax({paused: true});
						formAnim.set(formOuter, {css:{zIndex: 1000, overflowY: 'auto'}}, 0);
						formAnim.to(formOuter, 0.2, {opacity: 1, ease: Linear.easeNone}, 0.1);
						formAnim.to(formInner, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.1);
						formAnim.set(formClose, {opacity: 1});
						formAnim.to(formClose, 0.3, {xPercent: -100, ease: Linear.easeInOut});

				$('.form-button').on('click', function() {
					if ($('body').hasClass('form-open')) {
						formAnim.reverse();
						$('body').removeClass('form-open');
					} else {
						$('body').addClass('form-open');
						formAnim.play();
					}
				});

				$('.form .grid-x').click(function(e) {
			if (e.target === this){
				if ($('body').hasClass('form-open')) {
					formAnim.reverse();
							$('body').removeClass('form-open');
							
							setTimeout(function(){
							formOuter.scrollTop(0);
							$(".form form").trigger('reset');
							$('input.input__field').parent().removeClass('input--filled');
							$('textarea.input__field').parent().removeClass('input--filled');
							}, 900);
						}
					}
				});

				if (sessionStorage) {
				var openForm = sessionStorage.getItem('openRestaurantForm');

				if (openForm === 'true') {
					$('body').addClass('form-open');
						formAnim.play();
				}

				sessionStorage.setItem('openRestaurantForm', 'false');
				}

				$('.form .close-button').click(function() {
			if ($('body').hasClass('form-open')) {
				formAnim.reverse();
						$('body').removeClass('form-open');

						setTimeout(function(){
						formOuter.scrollTop(0);
						$(".form form").trigger('reset');
						$('input.input__field').parent().removeClass('input--filled');
						$('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
					}
				});

				/*
				* Form send button scroll
				*/
				$('.form input[type="submit"]').on('click', function() {
					formOuter.scrollTop(formOuter[0].scrollHeight);
				});

				/*
				* Form unsuccesful submit
				*/
				document.addEventListener('wpcf7invalid', function(event) {
				formOuter.scrollTop(0);
				}, false );

				/*
				* Form success animation
				*/
				var wpcf7Elm = document.querySelector('.wpcf7'),
						formTitle = $('.form .form__title'),
						formText = $('.form .form__text'),
						formElem = $('.wpcf7-form-control-wrap'),
						formMessage = $('.wpcf7-response-output'),
						formSubmit = $('.wpcf7-submit');

				var formSuccess = new TimelineMax({paused: true});
						formSuccess.to(formText, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formElem, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formSubmit, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.set(formText, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formElem, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formSubmit, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formMessage, {css:{display: 'block !important'}}, 0.4);
						formSuccess.to(formMessage, 0.4, {opacity: 1}, 0.5);

				document.addEventListener('wpcf7mailsent', function(event) {
				formSuccess.play();

				setTimeout(function() {
					formAnim.reverse();
					$('body').removeClass('form-open');

						setTimeout(function(){
							formSuccess.reverse();
						formOuter.scrollTop(0);
						$(".form form").trigger('reset');
						$('input.input__field').parent().removeClass('input--filled');
						$('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
				}, 3000);
				}, false );

			}
		},
		page_template_template_restaurant: {
			init: function() {
				/*
				* Page load animation
				*/
				var pageContent = $('#restaurant .restaurant'),
		    		side = $('#restaurant .side');

				var overlayAnim = new TimelineMax({paused: true});
						overlayAnim.to(pageContent, 0.25, {opacity: 1}, 0.3);

				var sideAnim = new TimelineMax({paused: true});
						sideAnim.to(side, 1.4, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.3);

				window.addEventListener("load",function(event) {
					sideAnim.play();

					setTimeout(function(){
					  overlayAnim.play(0);
					}, 1000);
				}, false);

				/*
    		* Slick slider
    		*/
    		$('.slider').each(function() {
				  $(this).slick({
					  infinite: false,
					  dots: false,
						prevArrow: '<button type="button" class="slick-prev"></button>',
					  nextArrow: '<button type="button" class="slick-next"></button>'
					});
				});

				/*
				* Food menu animation
				*/
				var closeButton = $('.close-button');

				var table = $('#food-menu');

				var tableAnim = new TimelineMax({paused: true});
						tableAnim.to(table, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0);
						tableAnim.set(closeButton, {css:{zIndex: 3, display: 'block'}}, 0.8);
						tableAnim.to(closeButton, 0.3, {opacity: 1, ease: Linear.easeInOut}, 0.85);

				$('.button-menu').on('click', function() {
					if ($('body').hasClass('food-menu-open')) {
						tableAnim.reverse();
						$('body').removeClass('food-menu-open');
					} else {
						$('body').addClass('food-menu-open');
						tableAnim.play();
					}
				});

				$('.close-button').on('click', function() {
					if ($('body').hasClass('food-menu-open')) {
						tableAnim.reverse();
						$('body').removeClass('food-menu-open');
					}
				});

				/*
		    * WPCF7 Select2 dropdown
		    */
		    $('.form select').select2({
		    	minimumResultsForSearch: Infinity
		    });


				/*
				* Form open & close animation
				*/
				var formOuter = $('.form'),
		    		formInner = $('.form__container'),
		    		formClose = $('.form .form__close .close-button');

				var formAnim = new TimelineMax({paused: true});
						formAnim.set(formOuter, {css:{zIndex: 1000, overflowY: 'auto'}}, 0);
						formAnim.to(formOuter, 0.2, {opacity: 1, ease: Linear.easeNone}, 0.1);
						formAnim.to(formInner, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.1);
						formAnim.set(formClose, {opacity: 1});
						formAnim.to(formClose, 0.3, {xPercent: -100, ease: Linear.easeInOut});

				$('.form-button').on('click', function() {
					if ($('body').hasClass('form-open')) {
						formAnim.reverse();
						$('body').removeClass('form-open');
					} else {
						$('body').addClass('form-open');
						formAnim.play();
					}
				});

				$('.form .grid-x').click(function(e) {
        	if (e.target === this){
	        	if ($('body').hasClass('form-open')) {
		        	formAnim.reverse();
							$('body').removeClass('form-open');
							
							setTimeout(function(){
							  formOuter.scrollTop(0);
							  $(".form form").trigger('reset');
							  $('input.input__field').parent().removeClass('input--filled');
							  $('textarea.input__field').parent().removeClass('input--filled');
							}, 900);
						}
					}
				});

				if (sessionStorage) {
			    var openForm = sessionStorage.getItem('openRestaurantForm');

			    if (openForm === 'true') {
			    	$('body').addClass('form-open');
						formAnim.play();
			    }

			    sessionStorage.setItem('openRestaurantForm', 'false');
				}

				$('.form .close-button').click(function() {
        	if ($('body').hasClass('form-open')) {
	        	formAnim.reverse();
						$('body').removeClass('form-open');

						setTimeout(function(){
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');
						  $('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
					}
				});

				/*
				* Form send button scroll
				*/
				$('.form input[type="submit"]').on('click', function() {
					formOuter.scrollTop(formOuter[0].scrollHeight);
				});

				/*
				* Form unsuccesful submit
				*/
				document.addEventListener('wpcf7invalid', function(event) {
				  formOuter.scrollTop(0);
				}, false );

				$( function() {
					$( "#date-when" ).datepicker({ minDate: 0 });
				} );

				/*
				* Form success animation
				*/
				var wpcf7Elm = document.querySelector('.wpcf7'),
						formTitle = $('.form .form__title'),
						formText = $('.form .form__text'),
						formElem = $('.wpcf7-form-control-wrap'),
						formMessage = $('.wpcf7-response-output'),
						formSubmit = $('.wpcf7-submit');

				var formSuccess = new TimelineMax({paused: true});
						formSuccess.to(formText, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formElem, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formSubmit, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.set(formText, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formElem, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formSubmit, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formMessage, {css:{display: 'block !important'}}, 0.4);
						formSuccess.to(formMessage, 0.4, {opacity: 1}, 0.5);
 
				document.addEventListener('wpcf7mailsent', function(event) {
				  formSuccess.play();

				  setTimeout(function() {
				  	formAnim.reverse();
				  	$('body').removeClass('form-open');

						setTimeout(function(){
							formSuccess.reverse();
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');
						  $('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
				  }, 3000);
				}, false );

			}
		},
		// About us page
		page_template_template_about: {
			init: function() {
				/*
    		* About page Slick slider
    		*/
    		$('.slider').each(function() {
				  $(this).slick({
					  infinite: false,
					  dots: false,
						prevArrow: '<button type="button" class="slick-prev"></button>',
					  nextArrow: '<button type="button" class="slick-next"></button>'
					});
				});

				/*
				* Scrolling background change
				*/
				var controller = new ScrollMagic.Controller();

				window.addEventListener("load",function(event) {
					var sectionOneAnim = new TimelineMax({paused: true});
	      			sectionOneAnim.to($('.container-1 .section__content__inner'), 0.5, {opacity: 1, xPercent: -100, ease: Linear.easeNone}, 0.5);
	      			sectionOneAnim.to($('.container-1 .section__content__inner .logo'), 1, {scale: 1, ease: Elastic.easeOut.config(1, 0.5)}, 1);

	      	var section1 = new ScrollMagic.Scene({
					    triggerElement: '.container-1',
					    offset: -50,
					    reverse: false
					}).setTween(sectionOneAnim).addTo(controller);
	      });


				var sectionTwoAnim = new TimelineMax({paused: true});
      			sectionTwoAnim.to($('.container-2 .section__content__inner'), 0.5, {opacity: 1, xPercent: 100, ease: Linear.easeNone}, 0);

      	var section2 = new ScrollMagic.Scene({
				    triggerElement: '.container-2',
				    offset: -50,
				    reverse: false
				}).setTween(sectionTwoAnim).addTo(controller);


				var sectionThreeAnim = new TimelineMax({paused: true});
      			sectionThreeAnim.to($('.container-4 .section__content__inner'), 0.5, {opacity: 1, xPercent: -100, ease: Linear.easeNone}, 0);

      	var section3 = new ScrollMagic.Scene({
				    triggerElement: '.container-4',
				    offset: -50,
				    reverse: false
				}).setTween(sectionThreeAnim).addTo(controller);

				/*
				* Scrolling background change
				*/
				// var controller = new ScrollMagic.Controller();

				// var sectionOneAnim = new TimelineMax({paused: true});
    //   			sectionOneAnim.to($('body'), 0.3, {css:{backgroundColor: 'rgba(244,235,230,1)'}, ease: Linear.easeNone}, 0);

    //   	var section1 = new ScrollMagic.Scene({
				//     triggerElement: '.container-2',
				//     duration: 500,
				//     offset: -50
				// }).setTween(sectionOneAnim).addTo(controller);

				// var sectionOneItemParallaxAnim = new TimelineMax({paused: true});
    //   			sectionOneItemParallaxAnim.to($('.image-2'), 0.5, {y: -150, ease: Linear.easeNone}, 0);
    //   			sectionOneItemParallaxAnim.to($('.image-3'), 0.5, {y: -100, ease: Linear.easeNone}, 0);

    //   	var section1itemParallax = new ScrollMagic.Scene({
				//     triggerElement: '.container-2',
				//     duration: 740,
				//     offset: 0
				// }).setTween(sectionOneItemParallaxAnim).addTo(controller);


				// var sectionTwoAnim = new TimelineMax({paused: true});
    //   			sectionTwoAnim.to($('body'), 0.3, {css:{backgroundColor: 'rgba(166,137,105,1)'}, ease: Linear.easeNone}, 0);

    //   	var section2 = new ScrollMagic.Scene({
				//     triggerElement: '.container-3',
				//     duration: 500,
				//     offset: -50
				// }).setTween(sectionTwoAnim).addTo(controller);

				// var sectionTwoItemParallaxAnim = new TimelineMax({paused: true});
    //   			sectionTwoItemParallaxAnim.to($('.container-3 .text_container'), 0.5, {y: -200, ease: Linear.easeNone}, 0);

    //   	var section2itemParallax = new ScrollMagic.Scene({
				//     triggerElement: '.container-3',
				//     duration: 600,
				//     offset: 0
				// }).setTween(sectionTwoItemParallaxAnim).addTo(controller);


				// var sectionThreeAnim = new TimelineMax({paused: true});
    //   			sectionThreeAnim.to($('body'), 0.3, {css:{backgroundColor: 'rgba(40,40,40,1)'}, ease: Linear.easeNone}, 0);

    //   	var section3 = new ScrollMagic.Scene({
				//     triggerElement: '.container-4',
				//     duration: 500,
				//     offset: -50
				// }).setTween(sectionThreeAnim).addTo(controller);

				// var sectionThreeItemParallaxAnim = new TimelineMax({paused: true});
    //   			sectionThreeItemParallaxAnim.to($('.container-4 .text_container'), 0.5, {y: -250, ease: Linear.easeNone}, 0);

    //   	var section3itemParallax = new ScrollMagic.Scene({
				//     triggerElement: '.container-4',
				//     duration: 600,
				//     offset: 150
				// }).setTween(sectionThreeItemParallaxAnim).addTo(controller);

			}
		},
		// Contact page
		page_template_template_contact: {
			init: function() {
				/*
				* Google Maps ACF
				*/
				function center_map( map ) {
					var bounds = new google.maps.LatLngBounds();

					$.each( map.markers, function( i, marker ){
						var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

						bounds.extend( latlng );
					});

					if( map.markers.length === 1 ) {
					  map.setCenter( bounds.getCenter() );
					  map.setZoom( 10 );
					} else {
						map.fitBounds( bounds );
					}
				}

				function add_marker( $marker, map ) {
					var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

					var icon = {
		        url: siteinfo.tempdir + '/assets/images/marker.svg',
		        size: new google.maps.Size(70, 70),
		        origin: new google.maps.Point(0, 0)
					};

					var marker = new google.maps.Marker({
						position: latlng,
						map: map,
						icon: icon
					});

					map.markers.push( marker );

					if($marker.html()) {
						infoBubble = new InfoBubble({
				      map: map,
				      content: $marker.html(),
				      position: latlng,
				      shadowStyle: 0,
				      padding: 20,
				      minHeight: 230,
				      maxHeight: 230,
				      backgroundColor: '#000',
				      borderRadius: 0,
				      arrowSize: 15,
				      borderWidth: 0,
				      borderColor: '#000',
				      disableAutoPan: false,
				      hideCloseButton: false,
				      arrowPosition: 50,
				      backgroundClassName: 'infobubble',
				      arrowStyle: 0
						});

						$('.infobubble').parent().parent().addClass('infobubble-container');

						google.maps.event.addListener(marker, 'click', function() {
							$('.infobubble').parent().parent().addClass('infobubble-container');
							infoBubble.open();
						});
					}
				}

				function new_map( $el ) {
					var $markers = $el.find('.marker');

					var args = {
						zoom: 10,
						center: new google.maps.LatLng(0, 0),
						scrollwheel: false,
						draggable: true,
				    zoomControl: true,
					  mapTypeControl: false,
					  scaleControl: false,
					  streetViewControl: false,
					  rotateControl: false,
					  fullscreenControl: false,
						mapTypeId: 'Styled'
					};

					var styles = [ {
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 } ]
						}, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ]
						}, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ]
						}, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ]
						}, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ]
						}, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ]
						}, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ]
						}, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ]
						}, { "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ]
						}, { "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ]
						}, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ]
						}, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ]
						}, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ]
						}, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ]
						}
					];
					       	
					var map = new google.maps.Map( $el[0], args);

					var styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
  				
  				map.mapTypes.set('Styled', styledMapType);
					
					map.markers = [];
					
					$markers.each(function(){
				    add_marker( $(this), map );
					});
				
					center_map( map );
	
					return map;
				}

				var map = null;

				$(document).ready(function(){
					$('.gmap').each(function(){
						map = new_map( $(this) );
					});
				});

				google.maps.event.addDomListener(window, 'resize', function() {
			    center_map( map );
				});

				/*
				* Form open & close animation
				*/
				var formOuter = $('.form'),
		    		formInner = $('.form__container'),
		    		formClose = $('.form .form__close .close-button');

				var formAnim = new TimelineMax({paused: true});
						formAnim.set(formOuter, {css:{zIndex: 1000, overflowY: 'auto'}}, 0);
						formAnim.to(formOuter, 0.2, {opacity: 1, ease: Linear.easeNone}, 0.1);
						formAnim.to(formInner, 0.8, {css:{transform: 'translateX(0%)'}, ease: Expo.easeInOut}, 0.1);
						formAnim.set(formClose, {opacity: 1});
						formAnim.to(formClose, 0.3, {xPercent: -100, ease: Linear.easeInOut});

				$('.form-button').on('click', function() {
					if ($('body').hasClass('form-open')) {
						formAnim.reverse();
						$('body').removeClass('form-open');
					} else {
						$('body').addClass('form-open');
						formAnim.play();
					}
				});

				$('.form .grid-x').click(function(e) {
        	if (e.target === this){
	        	if ($('body').hasClass('form-open')) {
		        	formAnim.reverse();
							$('body').removeClass('form-open');
							
							setTimeout(function(){
							  formOuter.scrollTop(0);
							  $(".form form").trigger('reset');
							  $('input.input__field').parent().removeClass('input--filled');
							  $('textarea.input__field').parent().removeClass('input--filled');
							}, 900);
						}
					}
				});

				$('.form .close-button').click(function() {
        	if ($('body').hasClass('form-open')) {
	        	formAnim.reverse();
						$('body').removeClass('form-open');

						setTimeout(function(){
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');
						  $('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
					}
				});

				/*
				* Form send button scroll
				*/
				$('.form input[type="submit"]').on('click', function() {
					formOuter.scrollTop(formOuter[0].scrollHeight);
				});

				/*
				* Form unsuccesful submit
				*/
				document.addEventListener('wpcf7invalid', function(event) {
				  formOuter.scrollTop(0);
				}, false );

				/*
				* Form success animation
				*/
				var wpcf7Elm = document.querySelector('.wpcf7'),
						formTitle = $('.form .form__title'),
						formText = $('.form .form__text'),
						formElem = $('.wpcf7-form-control-wrap'),
						formMessage = $('.wpcf7-response-output'),
						formSubmit = $('.wpcf7-submit');

				var formSuccess = new TimelineMax({paused: true});
						formSuccess.to(formText, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formElem, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.to(formSubmit, 0.3, {opacity: 0, ease: Linear.easeNone}, 0);
						formSuccess.set(formText, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formElem, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formSubmit, {css:{display: 'none'}}, 0.3);
						formSuccess.set(formMessage, {css:{display: 'block !important'}}, 0.4);
						formSuccess.to(formMessage, 0.4, {opacity: 1}, 0.5);
 
				document.addEventListener('wpcf7mailsent', function(event) {
				  formSuccess.play();

				  setTimeout(function() {
				  	formAnim.reverse();
				  	$('body').removeClass('form-open');

						setTimeout(function(){
							formSuccess.reverse();
						  formOuter.scrollTop(0);
						  $(".form form").trigger('reset');
						  $('input.input__field').parent().removeClass('input--filled');
						  $('textarea.input__field').parent().removeClass('input--filled');
						}, 900);
				  }, 3000);
				}, false );

				/*
				* Restaurant button -> Open form
				*/
				$('.button-diamonds.restaurant-button').on('click', function() {
					if (sessionStorage) {
				    sessionStorage.setItem('openRestaurantForm', 'true');
					}
				});

				/*
				* Packages button -> Open form
				*/
				$('.button-diamonds.packages-button').on('click', function() {
					if (sessionStorage) {
				    sessionStorage.setItem('openPackagesForm', 'true');
					}
				});

			}
		},
		single_product: {
			init: function() {
			}
		},
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
